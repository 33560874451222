import DocImg from "../../../../src/components/docs/doc-img";
import linkFiveOverview from "../../../../src/assets/docs/app-store-connect/app-store-linkfive-connect.png";
import ascVendor from "../../../../src/assets/docs/app-store-connect/app-store-find-vendor-id.png";
import ascSK1 from "../../../../src/assets/docs/app-store-connect/app-store-subscription-key.png";
import * as React from 'react';
export default {
  DocImg,
  linkFiveOverview,
  ascVendor,
  ascSK1,
  React
};