import DocImg from "../../../../src/components/docs/doc-img";
import userSessionOverview from "../../../../src/assets/docs/user-management/linkfive-user-session-overview.png";
import userPlansOverview from "../../../../src/assets/docs/user-management/linkfive-user-plans-overview.png";
import userIdDetail from "../../../../src/assets/docs/user-management/linkfive-user-id-detail.png";
import userSessionDetail from "../../../../src/assets/docs/user-management/linkfive-user-session-detail.png";
import * as React from 'react';
export default {
  DocImg,
  userSessionOverview,
  userPlansOverview,
  userIdDetail,
  userSessionDetail,
  React
};