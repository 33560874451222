import DocImg from "../../../../src/components/docs/doc-img";
import addCustomPlanDialog from "../../../../src/assets/docs/user-management/linkfive-add-custom-plan.png";
import userSessionDetail from "../../../../src/assets/docs/user-management/linkfive-user-session-detail.png";
import userPlanCreated from "../../../../src/assets/docs/user-management/linkfive-custom-plan-created.png";
import * as React from 'react';
export default {
  DocImg,
  addCustomPlanDialog,
  userSessionDetail,
  userPlanCreated,
  React
};