import DocImg from "../../../../src/components/docs/doc-img";
import waitingForReview from "../../../../src/assets/docs/how-to/review-appstore/appstore-subscription-waiting-for-review.png";
import createPlayout from "../../../../src/assets/docs/how-to/review-appstore/appstore-subscription-create-playout.png";
import filterEmpty from "../../../../src/assets/docs/how-to/review-appstore/appstore-subscription-subscription-playout-filter-empty.png";
import filterOptions from "../../../../src/assets/docs/how-to/review-appstore/appstore-subscrption-filter-options.png";
import * as React from 'react';
export default {
  DocImg,
  waitingForReview,
  createPlayout,
  filterEmpty,
  filterOptions,
  React
};