import DocImg from "../../../../src/components/docs/doc-img";
import createSubStep1 from "../../../../src/assets/docs/connect-google/create_subscription_1.png";
import createSubStep2 from "../../../../src/assets/docs/connect-google/create_subscription_2.png";
import createSubStep3 from "../../../../src/assets/docs/connect-google/create_subscription_3.png";
import createSubStep4 from "../../../../src/assets/docs/connect-google/create_subscription_4.png";
import createSubStep5 from "../../../../src/assets/docs/connect-google/create_subscription_5.png";
import createSubStep6 from "../../../../src/assets/docs/connect-google/create_subscription_6.png";
import createSubStep7 from "../../../../src/assets/docs/connect-google/create_subscription_7.png";
import createSubStep8 from "../../../../src/assets/docs/connect-google/create_subscription_8.png";
import createSubStep9 from "../../../../src/assets/docs/connect-google/create_subscription_9.png";
import * as React from 'react';
export default {
  DocImg,
  createSubStep1,
  createSubStep2,
  createSubStep3,
  createSubStep4,
  createSubStep5,
  createSubStep6,
  createSubStep7,
  createSubStep8,
  createSubStep9,
  React
};