import DocImg from "../../../../src/components/docs/doc-img";
import addS2SNotification1 from "../../../../src/assets/docs/connect-apple-v2/find-app-store-server-to-server-notification-1-linkfive.png";
import addS2SNotification2 from "../../../../src/assets/docs/connect-apple-v2/find-app-store-server-to-server-notification-2-linkfive.png";
import addS2SNotification3 from "../../../../src/assets/docs/connect-apple-v2/find-app-store-server-to-server-notification-3-linkfive.png";
import createAppStoreKey1 from "../../../../src/assets/docs/connect-apple-v2/create-app-store-subscription-key-1-linkfive.png";
import createAppStoreKey2 from "../../../../src/assets/docs/connect-apple-v2/create-app-store-subscription-key-2-linkfive.png";
import createAppStoreKey3 from "../../../../src/assets/docs/connect-apple-v2/create-app-store-subscription-key-3-linkfive.png";
import findIssuerId from "../../../../src/assets/docs/connect-apple-v2/find-app-store-issuer-id-linkfive.png";
import * as React from 'react';
export default {
  DocImg,
  addS2SNotification1,
  addS2SNotification2,
  addS2SNotification3,
  createAppStoreKey1,
  createAppStoreKey2,
  createAppStoreKey3,
  findIssuerId,
  React
};