import DocImg from "../../../../src/components/docs/doc-img";
import googleNotificationsStep1 from "../../../../src/assets/docs/connect-google/google-notifications-1-linkfive.png";
import step1 from "../../../../src/assets/docs/connect-google/connect-linkfive-to-google-play-store-step-1.png";
import step2 from "../../../../src/assets/docs/connect-google/connect-linkfive-to-google-play-store-step-2.png";
import step3 from "../../../../src/assets/docs/connect-google/connect-linkfive-to-google-play-store-step-3.png";
import step4 from "../../../../src/assets/docs/connect-google/connect-linkfive-to-google-play-store-step-4.png";
import step5 from "../../../../src/assets/docs/connect-google/connect-linkfive-to-google-play-store-step-5.png";
import step6 from "../../../../src/assets/docs/connect-google/connect-linkfive-to-google-play-store-step-6.png";
import step7 from "../../../../src/assets/docs/connect-google/connect-linkfive-to-google-play-store-step-7.png";
import step8 from "../../../../src/assets/docs/connect-google/connect-linkfive-to-google-play-store-step-8.png";
import step9 from "../../../../src/assets/docs/connect-google/connect-linkfive-to-google-play-store-step-9.jpg";
import step10 from "../../../../src/assets/docs/connect-google/connect-linkfive-to-google-play-store-step-10.png";
import * as React from 'react';
export default {
  DocImg,
  googleNotificationsStep1,
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  step9,
  step10,
  React
};