import DocImg from "../../../../src/components/docs/doc-img";
import ascStep1 from "../../../../src/assets/docs/app-store-connect/app-store-connect-step-1.png";
import ascStep2 from "../../../../src/assets/docs/app-store-connect/app-store-connect-step-2.png";
import ascStep3 from "../../../../src/assets/docs/app-store-connect/app-store-connect-step-3.png";
import ascStep4 from "../../../../src/assets/docs/app-store-connect/app-store-connect-step-4.png";
import * as React from 'react';
export default {
  DocImg,
  ascStep1,
  ascStep2,
  ascStep3,
  ascStep4,
  React
};